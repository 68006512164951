import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { id: "a2023414" }
const _hoisted_2 = {
  key: 0,
  class: "common-main-hide"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "common-footer",
      style: _normalizeStyle({ backgroundColor: $props.backgroundColor })
    }, [
      ($setup.HIDE_HFY == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("ul", { innerHTML: $setup.footerHtml }, null, 8 /* PROPS */, _hoisted_3)
          ]))
        : ($setup.form.tankbidFooter)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              innerHTML: $setup.form.tankbidFooter
            }, null, 8 /* PROPS */, _hoisted_4))
          : _createCommentVNode("v-if", true),
      _createCommentVNode(" <div v-else class=\"common-main\">\n      <div class=\"m-left\">\n        <span class=\"c-title\">智能应用</span>\n        <div class=\"m-left-content\">\n          <router-link to=\"GovernByLaw\" target=\"_blank\" >依法治省（市）</router-link>\n          <router-link to=\"WLegislation\" target=\"_blank\">智慧立法</router-link>\n          <router-link to=\"WEnforce\" target=\"_blank\">智慧执法</router-link>\n          <router-link to=\"WSupervision\" target=\"_blank\">智慧监督</router-link>\n          <router-link to=\"WReconsideration\" target=\"_blank\">智慧复议</router-link>\n          <router-link to=\"LegalServices\" target=\"_blank\">公共法律服务</router-link>\n          <router-link to=\"/DataCenter\" target=\"_blank\" class=\"digital-twin\">数据中台</router-link>\n          <router-link to=\"KnowledgeCenter\" target=\"_blank\" class=\"digital-twin\">知识中台</router-link>\n        </div>\n      </div>\n      <div class=\"m-center\">\n        <span class=\"c-title\">联系我们</span>\n        <ul>\n          <li>北京市海淀区北四环西路67号中关村国际创新大厦11层</li>\n          <li>010-61137666（总机）</li>\n          <li>meijf@beidasoft.com</li>\n          <li style=\"opacity:0\">136&nbsp;1132&nbsp;1209（服务）</li>\n        </ul>\n      </div>\n      <div class=\"m-right\">\n        <span class=\"c-title\">敬请关注</span>\n        <i class=\"i-ewm\"></i>\n        <p>北软PKUSE</p>\n      </div>\n    </div> ")
    ], 4 /* STYLE */)
  ]))
}